import './components/jquery.responsifyBgImg';
import './vendor/owl.carousel.min';
import './vendor/isInViewport';
import './vendor/jquery.fitvids';
import './vendor/jquery.flexslider';
import './vendor/jquery.sticky-kit.min';
import './vendor/lity.min';
import './vendor/modernizr';
import './vendor/svg4everybody';

(function($) {

    // Global vars
    const $body = $('body');

	// Toggles

    $('.js-toggle').on('click', function(e) {
        e.preventDefault();
        var $this = $(this);
        var $type = $this.data('toggle');

        if ($type == 'nav') {
            $body.toggleClass('menu-open');
        }

        if ($type == 'submenu') {
            $this.parent().toggleClass('submenu-open');
        }

        if ($type == 'breadcrumbs') {
            $this.parent().toggleClass('bc-open');
        }

        if ($type == 'share') {
            $this.closest('ul').toggleClass('display-sharer');
        }
    });


    // Site Search

    var searchDisplay;

    $('.js-toggle-search').on('click', function() {

        if (searchDisplay == true) {
            hideSearch();
        } else {
            displaySearch();
        }
    });

    function displaySearch() {
        $body.addClass('search-open');
        $('.post-search__search-field').focus();
        searchDisplay = true;
    }

    function hideSearch() {
        $body.removeClass('search-open');
        searchDisplay = false;
    }


    // Owl Carousel

    const owlTimelineSlider = $('.owl-timeline-menu-carousel');

    owlTimelineSlider.owlCarousel({
        loop:false,
        margin:25,
        nav:true,
        dots: true,
        autoplay: true,
        smartSpeed: 750,
        responsive : {
            0 : {
                items:2,
                margin:10
            },
            390 : {
                items:3,
                margin:10
            },
            480 : {
                items:3
            },
            599 : {
                items:4,
                margin:25
            }
        }
    });

    const owlInlineTestimonialCarousel = $('.owl-inline-testimonial-carousel');
    owlInlineTestimonialCarousel.owlCarousel({
        loop:true,
        margin:25,
        nav:false,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        items: 1,
        transitionStyle : 'fade',
        autoHeight:true,
        autoplayTimeout: 7000
    });

    const owlFadeCarousel = $('.owl-fade-slider');
    owlFadeCarousel.owlCarousel({
        loop:true,
        nav:false,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        items: 1,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoplayTimeout: 4000
    });

    const owlCompanyCultureTestimonialCarousel = $('.owl-company-culture-testimonial-carousel');
    owlCompanyCultureTestimonialCarousel.owlCarousel({
        loop:true,
        margin:0,
        nav:false,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        items: 1,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoplayTimeout: 7000
    });

    const owlUspSectionsSlider = $('.owl-usp-sections-carousel');

    owlUspSectionsSlider.owlCarousel({
        loop:true,
        margin:25,
        nav:true,
        dots: true,
        autoplay: true,
        smartSpeed: 750,
        items: 1,
    });

    const owlTextCarousel = $('.owl-text-slider');
    owlTextCarousel.owlCarousel({
        loop:true,
        margin:25,
        nav:false,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        items: 1,
        autoplayTimeout: 3500
    });

    const owlInternalSlider = $('.owl-internal-slider');

    let slidecount = owlInternalSlider.data('slidecount');

    owlInternalSlider.owlCarousel({
        loop: true,
        margin:0,
        nav:true,
        dots: false,
        autoplay: false,
        smartSpeed: 1000,
        autoplayTimeout: 9000,
        items:1
    });

    owlInternalSlider.find('.owl-nav').append('<span class="owl__slidecount">01<span class="owl__slidecount__line"></span><span class="owl__total">0' + slidecount + '</span></span>');

    const owlRecentProjectsCarousel = $('.owl-recent-projects-carousel');
    let rp_slidecount = owlRecentProjectsCarousel.data('slidecount');

    owlRecentProjectsCarousel.owlCarousel({
        loop: true,
        margin:20,
        nav:true,
        dots: false,
        autoplay: false,
        smartSpeed: 1000,
        autoplayTimeout: 9000,
        responsive : {
            0 : {
                items:1,
                margin:10
            },
            480 : {
                items:2,
                margin:20
            },
            960 : {
                items:1,
                stagePadding: 300,
                margin:20
            },
            1024 : {
                items:2,
                stagePadding: 160,
                margin:20
            },
            1280 : {
                items:2,
                stagePadding: 280,
                margin:20
            },
            1400 : {
                items:3,
                stagePadding: 150,
                margin:20
            },
            1800 : {
                items:3,
                stagePadding: 250,
                margin:20
            },
            2100 : {
                items:4,
                stagePadding: 150,
                margin:20
            }
        }
    });

    owlRecentProjectsCarousel.find('.owl-nav').append('<span class="owl__slidecount">01<span class="owl__slidecount__line"></span><span class="owl__total">0' + rp_slidecount + '</span></span>');


    // $('.custom-owl-prev').on('click', showPrevSlide);
    // $('.custom-owl-next').on('click', showNextSlide);

    // function showPrevSlide(e) {
    //         var index = e.currentTarget.dataset.previndex;
    //         owlInternalSlider.trigger('to.owl.carousel', [index - 1, 300]);

    // }

    // function showNextSlide(e) {
    //         var index = e.currentTarget.dataset.nextindex;
    //         owlInternalSlider.trigger('to.owl.carousel', [index - 1, 300]);
    //         //$('.custom-owl-prev').data('nextindex');
    // }
    // Scroll to ID

    $('.js-scrollto').on('click', function () {
        var id = $(this).data('target');
        var offset = $(this).data('offset');
        var speed = $(this).data('speed');

        if (!speed) {
            speed = 'slow';
        }

        if (!offset) {
            offset = 0;
        }

        scrollTo(id, offset, speed);
    });

    function scrollTo(id, offset, speed){
        var el;
        var scrollPos;
        var winW = $(window).width();

        if (id === 'top') {
            scrollPos = 0;
        } else {
            el = $(id);
            scrollPos = el.offset().top + offset;
        }

        $('html,body').animate({scrollTop: scrollPos},speed);
    }

    // Responsive Background Images

    $('.js-responsive-bg').responsifybgimg();

	// Footcols

    $('.footcol h3').click(function() {
        $(this).next('.footcol__content').toggleClass('open');
    });

    // Sticky Header

    const $header = $('.site-header__fixed');
    var position = $(document).scrollTop();
    var offset = 40;
    var fixed = false;
    var mouseover = false;
    const winW = $(window).width();

    if (position > offset && winW > 959) {
        $header.addClass('is-fixed');
        fixed = true;
    }

    if (winW > 959) {

        $(document).on('scroll', function(){
            var scroll = $(document).scrollTop();   

            if(scroll > position) {
                if (position > offset) {
                    $header.addClass('is-fixed');
                    $header.removeClass('is-visible');
                    fixed = true;
                }
                
            } else {
                if (position <= offset || scroll === 0) {
                    
                    $header.removeClass('is-fixed');

                    if (!mouseover) {
                        $header.removeClass('is-visible');
                    }
                    fixed = false;
                }
            }   

            setTimeout(function() { 
                if (position < offset) {
                    $header.removeClass('is-fixed');

                    if (!mouseover) {
                        $header.removeClass('is-visible');
                    }
                    fixed = false;
                }
            }, 200);    

            position = scroll;
            
        });
    }

    // Sticky Form

    var $sticky_el = $('[data-sticky_column]');
    stickSidebar($sticky_el, winW);

    function stickSidebar(el, winW) {
        if ($sticky_el.length > 0) {
            
            var $headerH = $('.site-header').outerHeight();
            console.log($headerH);
            var offset = (winW > 959) ? 56 : $headerH;
            console.log('offset: ' + offset);
            $(function() {
                return $('[data-sticky_column]').stick_in_parent({
                parent: '[data-sticky_parent]',
                recalc_every: 5,
                offset_top: offset
                });
            });
        }
    }

    $(function() {
        var resizeEnd;
        $(window).on('resize', function() {
            clearTimeout(resizeEnd);
            resizeEnd = setTimeout(function() {
                $(document.body).trigger("sticky_kit:recalc");
                $(window).trigger('resize-end');
            }, 100);
        });
    });

    $(window).on('resize-end', function() {
        var winW = $(window).width();
        stickSidebar($sticky_el, winW);
    });

    // Geo My IP Category Filter Change

    $('select.gmw-taxonomy').on('change', function() {
        $('.gmw-submit').trigger('click');
    });

     // Animate on Scroll

    $('.js-visible:in-viewport(0)').addClass('is-visible');

    $(window).scroll(function() {
        $('.js-visible:in-viewport(-200)').addClass('is-visible');
    });


    // Timeline Internal Scroll function

    $('.js-timeline').on('click', function() {
        var scrollTarget = $($(this).data('target'));
        //console.log(scrollTarget);
        var topPos = scrollTarget.offset();
        //console.log(topPos);
        innerScrollTo($('.timeline__list'), topPos.top-30, 600);
    });

    function innerScrollTo(element, to, duration) {
        var start = element.scrollTop(),
            change = to - start,
            currentTime = 0,
            increment = 20;
            console.log('to:' + to);
        var animateScroll = function(){        
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop(val);
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    //t = current time
    //b = start value
    //c = change in value
    //d = duration
    Math.easeInOutQuad = function (t, b, c, d) {
	    t /= d/2;
	    if (t < 1) return c/2*t*t + b;
	    t--;
	    return -c/2 * (t*(t-2) - 1) + b;
    };

    // Fitvids

    $('.js-fitvids').fitVids();

    // Read More Container

    function generateReadmore() {    
        
        var $el = $('.readmore');
        var itemHeight;
        var readMore =  '<a href="javascript:void(0);" class="js-readmore__button readmore__link" title="Read More"><span class="readmore__link__text">Read More</span> <svg class="readmore__icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/wp-content/themes/ecl/dist/img/icons.svg#arrow-down"></use></svg></a>';

        $el.each( function( index, element ){
            var $this = $(this);
            
            if ($this.find('.readmore__link').length > 0 ) {
                return;
            }

            itemHeight = $this.find('.readmore__text').height();
            console.log(itemHeight);
            if (itemHeight > 86) {
                $this.addClass('hidden-content');
                $this.find('.readmore__actions').prepend(readMore);
            }
        });

        $('.js-readmore__button').click(function() {
            var $textel = $(this).find('.readmore__link__text');

            ($textel.text() == 'Read More') ? $textel.text('Read Less').parent().attr('title', 'Read Less') : $textel.text('Read More').parent().attr('title', 'Read More');

            $(this).parent().prev('.js-readmore__container').toggleClass('open');
        });
    }

    generateReadmore();

    // Populate hidden PDF file url

    $('body').on('click', '.js-email-pdf', function(){
        var fileurl = $(this).data('fileurl');
        $('[name="input_7"]').val(fileurl);
    });
})(jQuery);

function toggleNav() {
    jQuery('body').toggleClass('menu-open');
}